import React from "react";
import {
  H1 as H1_,
  Text as Text_,
  Image,
  Container,
  Box,
  Stack,
} from "components/core";
import { styled } from "stitches.config";
import ImageAppDemo from "assets/images/app-demo.png";
import Layout from "components/Layout";
import Seo from "../components/Seo";
import ImageGooglePlay from "assets/images/google-play.svg";
import ImageAppleStore from "assets/images/apple-store.svg";

const BoxStack = styled(Box, {
  variants: {
    type: {
      desktop: {
        pr: "$9",
        textAlign: "left",
      },
      mobile: {
        pr: "$0",
        pb: "$9",
        textAlign: "center",
      },
    },
  },
  defaultVariants: {
    type: "destop",
  },
});

const StackLink = styled(Stack, {
  variants: {
    type: {
      desktop: {
        mt: "$12",
      },
      mobile: {
        mt: "$11",
      },
    },
  },
  defaultVariants: {
    type: "destop",
  },
});

const BoxImage = styled(Box, {
  variants: {
    type: {
      desktop: {
        pb: "$0",
      },
      mobile: {
        pb: "$9",
      },
    },
  },
  defaultVariants: {
    type: "destop",
  },
});

const H1 = styled(H1_, {
  lineHeight: "$5",
  color: "$primary",
});

const Text = styled(Text_, {
  color: "$primary",
});

const Index = () => (
  <Box
    css={{
      background:
        "linear-gradient(180deg, rgba(58, 186, 228, 0.2) 0%, rgba(234, 255, 238, 0.2) 87.94%, rgba(234, 255, 238, 0) 100%)",
    }}
  >
    <Seo />
    <Layout>
      <Container size={3}>
        <Stack direction={{ "@initial": "column-reverse", "@bp2": "row" }}>
          <BoxStack
            type={{ "@initial": "mobile", "@bp2": "desktop" }}
            css={{ flex: 1 }}
          >
            <H1 type={{ "@initial": "mobile", "@bp2": "desktop" }}>
              Localise les patinoires de Montréal
            </H1>
            <Text type={{ "@initial": "mobile", "@bp2": "desktop" }}>
              Trouve une patinoire adaptée à ton activité et consulte l’état de
              la glace grâce aux données de la ville.
            </Text>
            <StackLink
              justify={{ "@initial": "center", "@bp2": "start" }}
              gap={8}
              type={{ "@initial": "mobile", "@bp2": "desktop" }}
            >
              <Image
                src={ImageAppleStore}
                css={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(" https://apps.apple.com/app/id1606832114");
                }}
              />
              <Image
                src={ImageGooglePlay}
                css={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=ca.patinoiresmontreal"
                  );
                }}
              />
            </StackLink>
          </BoxStack>

          <BoxImage
            type={{ "@initial": "mobile", "@bp2": "desktop" }}
            css={{ flex: 1 }}
          >
            <Image src={ImageAppDemo} />
          </BoxImage>
        </Stack>
      </Container>
    </Layout>
  </Box>
);

export default Index;
